import React from "react";
import PageWrapper from "../components/PageWrapper";
import ContentLips from "../sections/enhance/enhancecontent.js";
import Herolips from "../sections/enhance/enhancehero.js";
import Testimonial from "../sections/index/indexreviews.js";

import Rte from '../sections/landing/popular';
import Saveface from '../sections/landing/saveface';
import Brand from "../sections/homepage/Brands.js";
import Lipsgallery from "../sections/enhance/enhancegallery.js";

import RecoveryLips from "../sections/enhance/enhancerecovery.js";

import Head from "../components/head";
import Pro from '../sections/nose/nosesteps.js';
import Clinic from '../sections/nose/nosefind.js';  
import Award from "../sections/index/award.js";
import { JsonLd } from '../components/JsonLd';

const LipsEhancementPage = () => {
  return (
    <>
    <Head title="Lip Ehancements London" image="/images/dermamina-home.jpg"  description="Get irresistibly plump lips with our expert lip enhancement treatments. Enhance your beauty today!" keywords="Lip fillers, Lip augmentation, Dermal fillers, Lip enhancement, Lip injections, Plump lips, Lip filler London, Natural lip augmentation, Lip rejuvenation, Non-surgical lip enhancement, 0.5 ml Lip Filler, 1ml Lip filler, 0.5 and 1ml lip filler, make lips bigger, lip filler before and after"/>


    <JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'Organization',
'@id': 'https://www.dermamina.com/#organization',
url: 'https://www.dermamina.com',
name: 'Dermamina',
contactPoint: {
  '@type': 'ContactPoint',
  telephone: '020 7099 6650',
  contactType: 'Customer service',
},
address:{
  '@type': 'PostalAddress',
streetAddress: '114 New Cavendish Street',
addressRegion:'London',
postalCode:'W1W 6XT',
addressLocality:'Westminster',
},
image: {
  '@type': 'ImageObject',
  url: 'https://dermamina.com/images/dermamina-home.jpg',   
},
logo: {
'@type': 'ImageObject',
url: 'https://dermamina.com/images/dermamina-logo.svg',   
width: '200',
},
'name': 'dermamina',
'sameAs': [

'https://www.facebook.com/DermaMina-1985252831708400/',
'https://www.instagram.com/dermamina',
],
}}
</JsonLd>



<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'BreadcrumbList',
'itemListElement': [
  {'@type': 'ListItem', 
position:1,
name:'Dermamina Clinic',
item:'https://www.dermamina.com',
},

{'@type': 'ListItem', 
position:2,
name:'Lip Filler London',
item:'https://www.dermamina.com/lip-enhancements',
},

{'@type': 'ListItem', 
position:3,
name:'Dermal Fillers London',
item:'https://www.dermamina.com/lip-filler',
},

]
}}
</JsonLd>


<PageWrapper headerDark>
<Herolips />
<RecoveryLips />
<ContentLips/>
<Lipsgallery />
<Award />

<Testimonial />



<Pro />
     
<Clinic /> 
<Rte />

<Brand />
<Saveface />
</PageWrapper>

    </>
  );
};
export default LipsEhancementPage;
